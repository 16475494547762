import React from 'react';
// import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Layout } from '../components';
import { Container } from '../styles/shared';

// const PostsWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;

const About = () =>  (
    <Layout>
    <Helmet title={`About | David Formentin`} />
      <Container>
        About
      </Container>
    </Layout>
  );


export default About;